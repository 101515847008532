<template>
    <div class="paysucc_box">
        <div class="icon">
            <img src="./image/paysuccess.png" alt="" />
        </div>
        <p class="dec">您已支持成功{{ amount }}元</p>
        <p class="dec">
            感谢您对小动物的爱心捐助，小小的爱，<br />会凝聚大大的美好
        </p>
        <button class="share-btn" @click="sharebox = true">帮他转发</button>
        <button class="order-btn" @click="goOrder" v-if="isSelect">
            查看项目
        </button>
        <button class="order-btn" @click="openQrcode" v-else>
            关注公众号领取激活相关服务
        </button>
        <div class="bx_box">
            <!-- <wx-open-launch-weapp
                id="launch-btn"
                appid="wx24eeb2ba0309b109"
                :path="adv_link_miniapp"
            >
                <script type="text/wxtag-template">
                    <style>
                        .ad-img{
                            width:100%;
                            max-width:100%;
                            overflow:hidden;

                        }
                     </style>

                    <img class="ad-img"  src="https://m.jisuchou.net/fund/minapp-ad.png" alt="" />
                </script>
            </wx-open-launch-weapp> -->
        </div>

        <!-- <a
            v-if="adv_link"
            href="javascript:;"
            @click="onAdvClick"
            class="bx_box"
        >
        </a> -->

        <img
            class="ad-img"
            src="./image/minapp-ad-2.png"
            alt=""
        />
        <div style="height: 100px;"></div>

        <div class="share-box" v-if="sharebox" @click="sharebox = false">
            <img src="./image/share.png" alt="" />
        </div>
        <div v-if="isShow" class="qrcode-box">
            <div class="mock"></div>
            <div class="content">
                <i @click="isShow = false"></i>
                <img src="./image/qrcode-new.png" alt="" />
                <p>长按识别二维码关注</p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import utils from '@/common/js/utils';
import { shareProj } from '@/common/js/mmcModule';
import { authChecker, tracking } from '@/common/js/mmcModule';
import saUtils from 'sensors-data-utils';
import groupUnifiedTracking from '@/common/js/groupUnifiedTracking';

const params = utils.getRequestParams();
const pageKey = 'H5_JSB_PaymentSuccess';
const session = groupUnifiedTracking.getSession();

export default {
    data() {
        return {
            sharebox: false,
            amount: 0,
            isShow: false,
            isSelect: false,
            sysSession: session,
        };
    },
    computed: {
        ...mapState(['jsbrnd']),

        adv_link() {
            return `https://m.qsebao.com/cbb/landpage?scene_name=pingan-property-pet-shjf--land-page&sku_name=pingan-property-pet-shjf&rc=orion.ct.baoxian.cwc_paysucc.20230524&ssid=${session.ssid}`;
        },
        adv_link_miniapp() {
            return `pages/tabBar/index?bi_cf=xcx.cw.qsnc.adposition.petfund.0828&ssid=${session.ssid}`;
        },
    },
    mounted() {
        const { amount, isSelect } = params;
        this.isSelect = isSelect == 0 ? true : true;
        this.amount = amount;
        const that = this;
        authChecker.check({ notneedphone: 1 }, () => {
            that.initShare();

            this.reportData();
        });
    },
    methods: {
        initShare() {
            const { projuuid } = params;
            const shareParams = {
                projuuid,
                shareRecord: {
                    scene: pageKey,
                },
            };
            shareProj.init(shareParams);
        },
        openQrcode() {
            this.isShow = true;
        },
        goOrder() {
            const { projuuid } = params;
            this.$router.push({
                path: '/fund/project/detail',
                query: {
                    projuuid,
                },
            });
        },

        reportData() {
            tracking.init();

            saUtils.init(pageKey);
            saUtils.update({
                jsbrnd: this.jsbrnd,
            });
            saUtils.pv();
        },

        onAdvClick() {
            saUtils.click('paySuccessAdv');

            // location.href = this.adv_link;
            console.log(wx);
            // wx.navigateToMiniProgram({
            //     appId: 'wx24eeb2ba0309b109',
            //     path:
            //         'pages/tabBar/index?bi_cf=xcx.cw.qsnc.adposition.petfund.0828',
            // });
        },
    },
};
</script>
<style scoped lang="less">
.paysucc_box {
    width: 100vw;
    height: 100vh;
    padding: 5px 26px;
    .icon {
        width: 200px;
        height: 169px;
        margin: 0 auto;
        overflow-y: hidden;
        img {
            width: 100%;
        }
    }
    .dec {
        font-size: 17px;
        margin-top: 7px;
    }
    .share-btn {
        display: block;
        border: none;
        width: 100%;
        background-image: linear-gradient(90deg, #40b9ff 0%, #009bff 100%);
        box-shadow: 0 2px 15px 0 rgba(0, 155, 255, 0.4);
        border-radius: 4px;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        font-weight: bold;
        margin-top: 20px;
    }
    .order-btn {
        display: block;
        width: 100%;
        border: none;
        background: #ffffff;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        opacity: 0.87;
        font-size: 18px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 50px;
        font-weight: bold;
        margin-top: 15px;
    }

    .ad-img {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
    }
    .share-box {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        img {
            width: 76%;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .qrcode-box {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .mock {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
        }
        .content {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 264px;
            height: auto;
            i {
                display: inline-block;
                width: 33px;
                height: 33px;
                background: url('./image/close.png');
                background-size: 100% 100%;
                float: right;
                transform: translateX(33px);
            }
            img {
            }
            p {
                font-size: 19px;
                color: #fff;
                margin-top: 20px;
            }
        }
    }
    .bx_box {
        margin-top: 30px;
        width: 100%;
        // height: 250px;
        border-radius: 8px;
        background-color: #fefefe;
        display: block;
        overflow: hidden;
        max-width: 100%;
        img {
            width: 100%;
            overflow: hidden;
            max-width: 100%;
        }
    }
}
</style>
